import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map, Observable, shareReplay, Subscription } from 'rxjs';
import { Bank, VoucherConfig } from "@angular-monorepo/api-services"
import { EnvironmentService } from '@angular-monorepo/environment';
import { Processors } from '../processor-selectable/processor-selectable.interfaces';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'ps-processor-networks',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './processor-networks.component.html',
    styleUrls: ['./processor-networks.component.scss'],
})
export class ProcessorNetworkseComponent implements OnInit, OnDestroy {
    private readonly subscriptions = new Subscription();
    @Input() config: VoucherConfig | undefined
    @Input() voucherPayin: boolean = false;
    @Output() onSelectProcessor = new EventEmitter<Processors>();
    @Output() changeMethod = new EventEmitter<string>();

    isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width:480px)']).pipe(
        map((result: BreakpointState) => result.matches),
        shareReplay(1),
    )

    activedFilter: string | undefined;
    activeBank: Bank | undefined = undefined;
    elementByPage: number = 8;
    totalPages: number = 1;
    processors: any[] = []
    activeProcessors: any[] = [];
    processorsCash: Bank[] = [];
    processorsBankTransfer: Bank[] = [];
    processorsWallet: Bank[] = [];
    activePage: number = 1;
    esPrimeraCarga: boolean = true;

    getPagesArray(): number[] {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }
    constructor(private readonly breakpointObserver: BreakpointObserver, private environmentService: EnvironmentService) { }

    ngOnInit(): void {
        this.isMobile$.subscribe(isMobile => {
            this.elementByPage = isMobile ? 6 : 12;
        })

        this.processorsCash = this.config ? this.config.bankList?.filter(b => {
            return b.paymentMethodInstructions?.some(instruction => instruction.method === "Cash")
        }) : [];

        this.processorsBankTransfer = this.config ? this.config.bankList?.filter(b => {
            return b.paymentMethodInstructions?.some(instruction => instruction.method === "BankTransfer")
        }) : [];

        this.processorsWallet = this.config ? this.config.bankList?.filter(b => {
            return b.paymentMethodInstructions?.some(instruction => instruction.method === "Wallet")
        }) : [];

        this.activedFilter = this.config?.methodSelected || this.config?.instruction_payment_methods[0];
        this.changeActivedProccesors();

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setActivePage(page: number) {
        this.activePage = page;
        this.paginar();
    }

    onNextPage() {
        if (this.activePage >= this.totalPages) return;
        this.activePage = this.activePage + 1;
        this.paginar();
    }

    onPreviousPage() {
        if (this.activePage <= 1) return;
        this.activePage = this.activePage > 0 ? this.activePage - 1 : 0;
        this.paginar();
    }

    onSeleccionarFiltro(filtro: string) {
        this.activedFilter = filtro;
        this.changeMethod.emit(filtro)
        this.activePage = 1
        this.esPrimeraCarga = true
        this.changeActivedProccesors();
    }

    parseImage(processor: string): string {
        const environment = this.environmentService?.env?.production ? 'prod' : 'dev';

        if (processor === this.activeBank?.bankCode) {
            return `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V2/collectors/${processor}/secondary.webp`;
        } else {
            return `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V2/collectors/${processor}/primary.webp`;
        }
    }

    changeActivedProccesors() {
        switch (this.activedFilter) {
            case "Wallet":
                this.processors = this.processorsWallet;
                break;

            case "BankTransfer":
                this.processors = this.processorsBankTransfer;
                break;

            case "Cash":
                this.processors = this.processorsCash
                break;

            default:
                this.processors = this.processorsWallet
                break;
        }

        this.paginar();

    }

    paginar() {
        const startIndex = (this.activePage - 1) * this.elementByPage;
        const endIndex = startIndex + this.elementByPage;
        this.activeProcessors = this.processors.slice(startIndex, endIndex);
        this.totalPages = Math.ceil(this.processors.length / this.elementByPage);

        if (this.esPrimeraCarga) {
            const collectorSelected = this.config?.collectorSelected ? this.activeProcessors.find(p => p.bankCode === this.config?.collectorSelected) : undefined;
            const active = collectorSelected ? collectorSelected : this.activeProcessors[0]

            this.selectProcessor(active)
            this.esPrimeraCarga = false;
        }
    }

    selectProcessor(processor: Bank) {
        const environment = this.environmentService?.env?.production ? 'prod' : 'dev';
        this.activeBank = processor
        const processorRef = {
            name: processor.bankCode,
            img: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V1/collectors/${processor.bankCode}/banner.svg`,
            hexColor: processor?.hexColor || '',
        } as unknown as Processors
        this.onSelectProcessor.emit(processorRef)
    }
}
