<!-- TODO: Implementar lazy loading mas adelante cuando el iframe lo soporte -->
<!-- @defer () { -->
<div class="container" [ngClass]="isAutoClass ? 'auto' : ''">
  <dcv-direct-connections-voucher-container (onShowTemplate)="onLoadingExport($event)"></dcv-direct-connections-voucher-container>
</div>
<!-- } @loading {
<div class="spinner">
  <ui-custom-spinner></ui-custom-spinner>
</div>
} -->
