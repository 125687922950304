<div class="container">
  @if(metodos && metodos.length > 1 && voucherConfiguracion && voucherConfiguracion.version === "V2") {
  <div style="margin: 10px 30px;">
    <div class="menu">
      <ng-container *ngFor="let metodo of metodos">
        <div class="menu__item" [ngClass]="{ 'menu__item--seleccionado': metodoSeleccionado === metodo }" (click)="onSeleccionarMetodo(metodo)">
          <p class="menu__item__label" [ngClass]="{ 'menu__item__label--seleccionado': metodoSeleccionado === metodo }">
            {{ metodo.label }}
          </p>
        </div>
      </ng-container>
    </div>

    <h2 class="titulo">{{title}}</h2>
  </div>
  }

  @else {
  <div class="title" [ngStyle]="{'background-color': color == 'primary' || !color ? '#395ae8' :  '#6A00F4'}">
    @if(title) {
    <h2>{{title}}</h2>
    }
  </div>
  }

  @if (linearSteps.length > 0) {
  <div class="steps">
    <sh-linear-step-info [color]="color" [linearSteps]="linearSteps" [clipboard]="clipboard"></sh-linear-step-info>
  </div>
  }
</div>