import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from '@angular-monorepo/shared';

@Component({
  selector: 'dvc-error',
  standalone: true,
  imports: [CommonModule, ErrorPageComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent { }
