import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { LinearStepInfo } from '../linear-step-info';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import { MediaButtonComponent } from '@angular-monorepo/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TimerService } from '../../services/timer.service';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'sh-pv-qr-step-info',
  standalone: true,
  providers: [ResponsiveService],
  imports: [CommonModule, MediaButtonComponent, MatButtonModule, MatIconModule],
  templateUrl: './pv-qr-step-info.component.html',
  styleUrl: './pv-qr-step-info.component.scss',
})
export class PvQrStepInfoComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() layout: 'qr' | 'code' | 'default' = 'default';
  @Input() voucherData: any;
  base64QRCode: string;
  hideDownloadQR: boolean = true;
  @ViewChild('contenedor', { static: true }) contenedor: ElementRef;

  timerString: string;
  classString: string;
  titleTimer: string;
  timerSubscription: Subscription;
  classSubscription: Subscription;
  titleTimerSubscription: Subscription;
  isDeuna: boolean = false;
  tiempo: string = ""
  estaExpirado: boolean = false

  constructor(
    private readonly captureScreenService: CaptureScreenService,
    private responsiveService: ResponsiveService,
    private timerService: TimerService
  ) {
    this.timerString = '00:00:00';
  }

  ngOnInit(): void {
    this.setDeuna();
    setInterval(() => {
      this.iniciarContador();
    }, 1000);
  }

  setDeuna() {
    this.isDeuna = this.voucherData && this.voucherData.processor.parent == 'Deuna';
    if (this.isDeuna) {
      if (this.voucherData?.operation?.status == '5') {
        this.timerString = '<b>Pago exitoso</b>'
        this.classString = 'green';
        this.titleTimer = 'Estado';
      } else if (this.voucherData?.operation?.status == '3') {
        this.timerString = '<b>Pago expirado</b>'
        this.classString = 'red'
        this.titleTimer = 'Estado';
      } else {
        this.timerService.startTimer(this.voucherData.voucherData.expirationDate);
        this.timerSubscription = this.timerService.timerString$.subscribe(timer => {
          this.timerString = timer;
        });
        this.classSubscription = this.timerService.classString$.subscribe(classStr => {
          this.classString = classStr;
        });

        this.titleTimerSubscription = this.timerService.titleTimer$.subscribe(title => {
          this.titleTimer = title;
        });
      }


    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }

    if (this.layout == 'qr' || this.layout == 'code') {

      this.removeWhiteBackground(this.linearSteps[0].label.value);
    }
  }


  removeWhiteBackground(base64: string) {

    const image = new Image();
    image.src = base64;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);


      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;


      for (let i = 0; i < data.length; i += 4) {
        if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {

          data[i + 3] = 0;
        }
      }


      ctx.putImageData(imageData, 0, 0);


      this.base64QRCode = canvas.toDataURL('image/png');
    };
  }


  async onDescargar() {
    try {
      await this.captureAndDownload();
    } catch (error) {
      console.error('Error downloading barcode image:', error);
    }
  }

  async captureAndDownload() {
    try {

      await new Promise(resolve => setTimeout(resolve, 500));

      const response: string = await this.captureScreenService.captureWithPromise('download-qr');

      if (!response.startsWith('data:image/png;base64,')) {
        throw new Error('Invalid image format');
      }

      setTimeout(() => {
        const a = document.createElement('a');
        a.href = response;
        a.download = 'code_qr_monnet.png';
        a.click();
        this.hideDownloadQR = true;
      }, 200);
    } catch (error) {
      console.error('Error during capture and download process:', error);
    }
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.classSubscription) {
      this.classSubscription.unsubscribe();
    }
    if (this.titleTimerSubscription) {
      this.titleTimerSubscription.unsubscribe();
    }

  }

  onRedirect() {
    window.location.href = this.voucherData.voucherData.actionLink
  }

  private iniciarContador() {
    console.log(this.voucherData)
    const expirationDate = moment(this.voucherData.voucherData.expirationDate).utc(true).diff(moment(), 'seconds');

    if (expirationDate <= 0) {
      this.estaExpirado = true;
      return
    }

    const duration = moment.duration(expirationDate, 'seconds');
    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');

    this.tiempo = `${hours}: ${minutes}: ${seconds}`;
  }
}
