import { Component, EventEmitter, OnInit, Output, ViewChild, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent, CustomSpinnerComponent, MediaButton } from '@angular-monorepo/ui';
import { DirectConnectionsVoucherComponent } from '../direct-connections-voucher/direct-connections-voucher.component';
import { VoucherHeaderComponent } from '../voucher-header/voucher-header.component';
import { Banner, BannerComponent, InstructionStepper, LinearStepFontSize, LinearStepInfo, LinearStepStatusColor } from '@angular-monorepo/shared';
import { ProcessorSelectableComponent, ProcessorNetworkseComponent, Processors, } from '@angular-monorepo/processor-selection';
import { CashProcessorService, GetPaymentPayloadRequest, GetPaymentResponseRequest, PaymentMethodInstruction, PaymentMethodPersonaType } from '@angular-monorepo/api-services';
import { DirectConnectionsVoucher } from '../direct-connections-voucher/direct-connections-voucher.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { bankTransferPaymentMethodButton, cashPaymentMethodButton, walletPaymentMethodButton } from './direct-connections-voucher.utils';
import { CountdownComponent, CountdownModule } from 'ngx-countdown';
import { PdfJsService } from '@angular-monorepo/pdf';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import moment from 'moment';
import { VoucherPdfContainerComponent } from '../voucher-pdf-container/voucher-pdf-container.component';
import { EnvironmentService } from '@angular-monorepo/environment';
import { ProcessorContainerComponent } from "@angular-monorepo/processor-selection"

@Component({
  selector: 'dcv-direct-connections-voucher-container',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    DirectConnectionsVoucherComponent,
    VoucherHeaderComponent,
    ProcessorSelectableComponent,
    ProcessorNetworkseComponent,
    BannerComponent,
    CustomSpinnerComponent,
    CountdownModule,
    VoucherPdfContainerComponent,
    ProcessorContainerComponent,
  ],
  templateUrl: './direct-connections-voucher-container.component.html',
  styleUrl: './direct-connections-voucher-container.component.scss',
})
export class DirectConnectionsVoucherContainerComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @Output() onShowTemplate: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  jsPdfService = inject(PdfJsService)
  screenRecordService = inject(CaptureScreenService)

  showTemplate: boolean = false

  isLoading = false;

  expirationTime: number = 0;
  expirationTimeFormated: string = '';
  expirationTimeTitleFormated: string = '';
  directConnectionsVoucherInput: DirectConnectionsVoucher = {} as DirectConnectionsVoucher;
  paymentParemeters: GetPaymentPayloadRequest = {} as GetPaymentPayloadRequest;

  voucherData: GetPaymentResponseRequest = null as unknown as any;
  selectedProcessor: Processors = null as any

  linearSteps: LinearStepInfo[] = []
  processors: Processors[] = []

  paymentMethod: string = 'Cash'

  get bankTransferButton(): MediaButton {
    return bankTransferPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'BankTransfer'
      this.changeProcessorInstructions()
    })
  }

  get cashButton(): MediaButton {
    return cashPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Cash'
      this.changeProcessorInstructions()
    })
  }

  get walletButton(): MediaButton {
    return walletPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Wallet'
      this.changeProcessorInstructions()
    })
  }

  get banner(): Banner {
    return {
      processor: this.selectedProcessor.name,
      text: 'Estás Pagando con',
      img: this.selectedProcessor.img,
      background: this.selectedProcessor.hexColor || '',
    };
  }

  get instructions(): PaymentMethodInstruction[] {
    let instructions = this.voucherData?.voucherConfig?.bankList.find((bankInstruction) => bankInstruction.bankCode === this.selectedProcessor?.name)?.paymentMethodInstructions

    return instructions || []
  }

  metodoPagoTipoPersona: PaymentMethodPersonaType;

  constructor(
    private cashProcessorService: CashProcessorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private environmentService: EnvironmentService
  ) {
    effect(() => {
      this.isLoading = this.cashProcessorService.paymentLoading()

      if (this.cashProcessorService.paymentData().voucherConfig) {
        this.voucherData = { ...this.cashProcessorService.paymentData(), voucherConfig: JSON.parse(this.cashProcessorService.paymentData()?.voucherConfig as unknown as any) }
        console.log(this.voucherData)
        this.paymentMethod = this.voucherData.voucherConfig.methodSelected || this.voucherData.voucherConfig.instruction_payment_methods[0]
        this.initTimer()
      }

      if (this.cashProcessorService.getPaymentError() || this.cashProcessorService.paymentData().errorMsg) {
        this.router.navigate(['/error'])
      }

      this.refreshVoucher()
    })

    effect(() => {
      if (this.screenRecordService.generatedImage()) {
        (() => {
          this.downloadImage(this.screenRecordService.generatedImage())
          this.onShowTemplateFunc(false)
          window.location.reload()
        })()
      }
    })
  }

  ngOnInit() {
    this.paymentParemeters = {
      operationId: this.activatedRoute.snapshot.params['operationId'],
      merchantId: this.activatedRoute.snapshot.params['merchantId'],
      verification: this.activatedRoute.snapshot.queryParams['verification'],
    }

    this.getPaymentInfo()
    this.onCreateIntervalToChangeStatus()
    this.setGetVoucherDataInterval()
  }

  handleCambioMetodo(metodo: PaymentMethodPersonaType) {
    this.metodoPagoTipoPersona = metodo;
  }

  refreshVoucher(): void {
    this.initLinearSteps()
    this.initInstructionSteps()
    this.initProcessors()
    this.changeProcessorInstructions()
  }

  initProcessors(): void {
    const environment = this.environmentService?.env?.production ? 'prod' : 'dev';
    this.processors = this.voucherData?.voucherConfig?.bankList.map((bank) => {
      return {
        name: bank?.bankCode || '',
        img: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V1/collectors/${bank.bankCode}/banner.svg`,
        hexColor: bank?.hexColor || '',
      } as unknown as Processors
    }) || []
    this.selectedProcessor = this.processors[0]
  }

  initLinearSteps(): void {
    const steps = []
    const labelStyle = 'color: #000;text-align: center;font-family: Poppins;font-size: 15px;font-style: normal;font-weight: 500;line-height: normal;'
    const valueStyleDesktop = 'color: #000;text-align: center;font-family: Poppins;font-size: 18px;font-style: normal;font-weight: 700;line-height: normal;'
    const valueStyleMobile = 'color: #000;text-align: center;font-family: Poppins;font-size: 16px;font-style: normal;font-weight: 700;line-height: normal;'
    const amount: LinearStepInfo = {
      label: {
        label: 'Monto a pagar',
        labelStyle: labelStyle,
        value: `<b>${this.voucherData?.currencyISO} ${this.voucherData?.amount}</b>`,
        valueStyle: window.innerWidth <= 600 ? valueStyleMobile : valueStyleDesktop,
        fontSize: LinearStepFontSize.SMALL,
      },
    }

    const paymentCode: LinearStepInfo = {
      label: {
        label: 'Código de pago',
        value: `<b>${this.voucherData?.paymentCode}</b>`,
        color: LinearStepStatusColor.BLUE,
        fontSize: LinearStepFontSize.LARGE,
      },
      clipboard: true,
      clipboardText: `${this.voucherData?.paymentCode}`,
    }

    if (window.innerWidth <= 600) {
      steps.push(paymentCode)
      steps.push(amount)
    }

    if (window.innerWidth > 600) {
      steps.push(amount)
      steps.push(paymentCode)
    }

    if (this.voucherData?.status === 'EXPIRADO') {
      steps.push({
        label: {
          label: `Estado`,
          value: 'Tiempo expirado',
          color: LinearStepStatusColor.RED,
          fontSize: LinearStepFontSize.SMALL,
        },
      })
    }

    if (this.voucherData?.status === 'AUTORIZADO') {
      steps.push({
        label: {
          label: `Estado`,
          value: 'Pago Realizado',
          color: LinearStepStatusColor.GREEN,
          fontSize: LinearStepFontSize.SMALL,
        },
      })
    }

    if (this.voucherData?.status === 'PENDIENTE_PAGO') {

      if (this.expirationTimeFormated === '00h : 00m : 00s') {
        steps.push({
          label: {
            label: `Estado`,
            value: 'Tiempo expirado',
            color: LinearStepStatusColor.RED,
            fontSize: LinearStepFontSize.SMALL,
          },
        })
      } else {
        steps.push({
          label: {
            label: `Vence en`,
            labelStyle: labelStyle,
            value: `<b>${this.expirationTimeFormated}</b>`,
            valueStyle: window.innerWidth <= 600 ? valueStyleMobile : valueStyleDesktop,
            sublabel: {
              text: this.expirationTimeTitleFormated,
              style: window.innerWidth <= 600 ?
                'color: #000;text-align: center;font-family: Poppins;font-size: 8px;font-style: normal;font-weight: 500;line-height: normal;margin-top: -0.2px;' :
                'color: #000;text-align: center;font-family: Poppins;font-size: 10px;font-style: normal;font-weight: 500;line-height: normal;',
            },
            fontSize: LinearStepFontSize.SMALL,
          },
        })
      }

    }

    this.linearSteps = steps
  }

  initInstructionSteps(): void {
    let directConnectionsVoucherData = {
      paymentMethodButtons: [],
      downloadButton: {
        imagesUrl: ['./assets/svg/download-file.svg'],
        label: 'Descargar instrucciones',
        color: 'secondary',
        onClick: () => {
          this.generatePdf()
        },
      },
      instructions: []
    }

    this.directConnectionsVoucherInput = directConnectionsVoucherData as unknown as DirectConnectionsVoucher
  }

  changeProcessorInstructions(): void {
    let instruction: InstructionStepper[] = this.findInstruction()
    let buttons: MediaButton[] = []

    this.uploadButton([])

    let cashMethod = this.findCashMethod()
    let bankTransferMethod = this.findBankTransferMethod()
    let walletMethod = this.findWalletMethod()

    this.updateVoucherInstructions(instruction)

    if (bankTransferMethod) {
      buttons.push(this.bankTransferButton)
    }

    if (cashMethod) {
      buttons.push(this.cashButton)
    }

    if (walletMethod) {
      buttons.push(this.walletButton)
    }

    this.uploadButton(buttons)
  }

  uploadButton(media: MediaButton[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      paymentMethodButtons: media,
    }
  }

  updateVoucherInstructions(instructions: InstructionStepper[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      instructions: instructions,
    }
  }

  handleChangeMethod(method: string) {
    this.paymentMethod = method
  }

  findInstruction(): InstructionStepper[] {
    if (!this.voucherData) {
      return []
    }

    const collectorsByMethod = this.voucherData.voucherConfig?.bankList?.filter(b => {
      return b.paymentMethodInstructions?.some(instruction => instruction.method === this.paymentMethod)
    });
    const processor = collectorsByMethod?.find(b => b.bankCode === this.selectedProcessor.name);
    if (processor) {
      const method = processor?.paymentMethodInstructions.find(m => m.method === this.paymentMethod);
      if (method) {
        const instructions = method?.instructions.map(i => {
          return {
            step: i.order,
            description: i.value,
          } as InstructionStepper
        })

        return instructions
      }
    }

    return []
  }

  findCashMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Cash') || null as any
  }

  findBankTransferMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('BankTransfer') || null as any
  }

  findWalletMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Wallet') || null as any
  }

  findPaymentMethodInstruction(method: string): PaymentMethodInstruction | undefined {
    return this.instructions?.find((instruction) => instruction.method === method)
  }

  findProcessor(code: string): Processors {
    return this.processors.find((p) => p.name === code) as Processors;
  }

  initTimer(): void {
    const expirationDate = moment(this.voucherData?.expiration).utc(true).diff(moment(), 'seconds');
    this.expirationTime = expirationDate;

    if (this.expirationTime > 86400) {
      const totalHours = Math.floor(this.expirationTime / 3600);
      const remainingTimeInSeconds = this.expirationTime % 3600;

      this.countdown.config = {
        leftTime: remainingTimeInSeconds,
        format: `${totalHours}'h' : mm'm' : ss's'`,
      };
    } else {
      this.countdown.config = {
        leftTime: this.expirationTime,
        format: `HH'h' : mm'm' : ss's'`,
      };
    }

    if (this.expirationTime) {
      moment.locale('es');
      const expirationMoment = moment().add(this.expirationTime, 'seconds');
      const formattedDate = expirationMoment.format('D MMM YYYY');
      const formattedTime = expirationMoment.format('HH:mm');

      this.expirationTimeTitleFormated = `Paga antes del ${formattedDate} - ${formattedTime} hrs.`;
    } else {
      this.expirationTimeTitleFormated = 'Fecha de expiración no disponible';
    }

    if (this.countdown) {
      this.countdown.restart();
    }
  }

  getPaymentInfo(): void {
    this.cashProcessorService.getPayment(this.paymentParemeters)
  }

  onProcessorChange(processor: Processors): void {
    this.selectedProcessor = processor;
    this.changeProcessorInstructions()
  }

  generatePdf(): void {
    this.onShowTemplateFunc(true)
    this.onSetDownloadButtonToLoading(true)

    setTimeout(() => {
      this.screenRecordService.captureScreen('voucherTemplate')
    }, 2000);
  }

  onShowTemplateFunc(data: boolean): void {
    this.showTemplate = data
  }

  generatePdfByImage(image: string): void {
    const pdfWidth = 210
    let voucherHeight = 300

    if (this.voucherData.voucherConfig.bankList.length === 1) {
      voucherHeight = 200
    }

    if (this.voucherData.voucherConfig.bankList.length === 2) {
      voucherHeight = 250
    }

    this.jsPdfService.addImage(
      image,
      'JPEG',
      0, //X posición en el PDF
      0, //Y posición en el PDF
      pdfWidth, //Ancho en el PDF
      voucherHeight
    );

    this.jsPdfService.save('Instrucciones de pago')
  }

  downloadImage(image: string): void {
    var a = document.createElement("a")
    a.href = image
    a.download = "Instrucciones de pago"
    a.click()
    this.onSetDownloadButtonToLoading(false)
  }

  onCreateIntervalToChangeStatus(): void {
    //Interval to update the timer
    const t = setInterval(() => {
      this.expirationTimeFormated = this.countdown.i.text as string
      if (this.expirationTimeFormated === '00h : 00m : 00s' && this.voucherData.status != 'AUTORIZADO') {
        this.voucherData.status = 'EXPIRADO'
        clearInterval(t)
      }

      this.initLinearSteps()
    }, 1000)
  }

  onSetDownloadButtonToLoading(loading: boolean): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      downloadButton: {
        ...this.directConnectionsVoucherInput.downloadButton,
        isLoading: loading,
      },
    }
  }

  setGetVoucherDataInterval(): void {
    setInterval(() => {
      this.getPaymentInfo()
    }, 60000)
  }
}
