<div class="container">
  <div class="steps" [ngClass]="column ? 'column' : ''">
    <ng-container *ngFor="let step of instruccionStepperInput; let i = index">
      <div class="step-container">
        <div class="step" [style.background-color]="(esResponsive$ | async) ? '' : getStepBackgroundColor()"
          [ngClass]="{'step-1': instruccionStepperInput[0] == step && color !== 'secondary' , 'step-4': instruccionStepperInput[3] == step }">
          <span [style.color]="(esResponsive$ | async) ? getStepColor() : '' ">
            {{step.step}} {{ (esResponsive$ | async) ? '.' : ''}}
          </span>
        </div>

        <ng-container *ngIf="step.title">
          <div class="title">
            <span [innerHTML]="step.title"></span>
          </div>
        </ng-container>

        <ng-container *ngIf="step.description">
          <div class="description">
            <span [innerHTML]="step.description"></span>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="i < (instruccionStepperInput.length - 1)">
        <div class="line-container">
          <div class="line"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>