<div class="container">
  @if(directConnectionsVoucherInput.paymentMethodButtons) {
  <div class="buttons">
    @if(directConnectionsVoucherInput.paymentMethodButtons.length > 1) {
    @for (mediaButton of directConnectionsVoucherInput.paymentMethodButtons; track $index) {
    <ui-media-button [mediaButtonInput]="mediaButton" />
    }}
  </div>
  }
  <div class="steps" id="voucher">
    <sh-instruction-stepper [color]="color" [instruccionStepperInput]="directConnectionsVoucherInput.instructions" />
  </div>
  <div class="actions">
    @if(directConnectionsVoucherInput.messageButton) {
    <div class="message-desktop">
      <ui-media-button [cursor]="'text'" [mediaButtonInput]="directConnectionsVoucherInput.messageButton" />
    </div>
    }
    @if (directConnectionsVoucherInput.downloadButton) {
    <div class="voucher-footer">
      <ui-media-button [mediaButtonInput]="directConnectionsVoucherInput.downloadButton" />
    </div>
    }
  </div>
</div>
