<div class="container-qr">
  <div class="qr-code">
    <div *ngIf="linearSteps[0].label.row && linearSteps[0].label.img" class="step" style="margin: 0.5rem;">
      <div class="label">
        <span class="title-qr">
          @if(!isDeuna){
          <ng-container *ngIf="esResponsive$ | async; else nonResponsiveText">
            Descarga el QR, súbelo a tu galería y <span class="highlight">págalo desde tu billetera favorita</span>
          </ng-container>
          <ng-template #nonResponsiveText>
            Escanea el QR y <span class="highlight">págalo desde tu billetera favorita</span>
          </ng-template>
          }@else{
          <ng-container *ngIf="esResponsive$ | async; else nonResponsiveText">
            Haz click a <span class="highlight">PAGAR</span> para redirigirte automáticamente a tu app de DEUNA.
          </ng-container>
          <ng-template #nonResponsiveText>
            Escanea el QR y <span class="highlight">págalo <br> desde tu wallet Deuna</span>
          </ng-template>
          }

        </span>
        <img class="image-qr" [src]="base64QRCode">
      </div>
      @if(!isDeuna){
      <button class="btn-download" mat-flat-button (click)="onDescargar()">
        <span class="span-btn">
          <img src="./assets/svg/download-file.svg" class="img-download" alt="download">
          Descargar
        </span>
      </button>
      }@else{
      <ng-container *ngIf="esResponsive$ | async; else desktopButton">
        <button class="btn-download" mat-flat-button (click)="onRedirect()">
          <span class="span-btn">
            PAGAR
          </span>
        </button>
      </ng-container>
      <ng-template #desktopButton>
        <button class="btn-download" mat-flat-button (click)="onDescargar()">
          <span class="span-btn">
            <img src="./assets/svg/download-file.svg" class="img-download" alt="download">
            Descargar
          </span>
        </button>
      </ng-template>
      }

    </div>
  </div>

  @if (esResponsive$ | async) {
  <div class="line-horizontal"></div>
  }@else{
  <div class="line-container-qr">
    <div class="line"></div>
  </div>
  }



  <div class="qr-data">
    <div class="step">
      <div class="label" [ngClass]="linearSteps[1].label.fontSize">
        <span class="title" [innerHTML]="linearSteps[1].label.label"></span>
        <div class="value">
          <span [innerHTML]="linearSteps[1].label.value" [ngClass]="linearSteps[1].label.color"></span>
        </div>
      </div>
    </div>

    @if (esResponsive$ | async) {
    <div class="line-container-qr">
      <div class="line"></div>
    </div>
    }@else{
    <div class="line-horizontal"></div>
    }

    <div class="step">

      @if(!isDeuna){
      <ng-container *ngIf="estaExpirado; else cronometro">
        <div class="estado">
          <p class="estado__titulo">Estado</p>
          <p class="estado__valor">Tiempo expirado</p>
        </div>
      </ng-container>
      <ng-template #cronometro>
        <div class="cronometro">
          <p class="cronometro__titulo">Vence en</p>
          <p class="cronometro__valor"><strong>{{ tiempo }}</strong> horas</p>
        </div>
      </ng-template>
      }@else{
      <div class="label" [ngClass]="linearSteps[2].label.fontSize">
        <span class="title font-size" [innerHTML]="titleTimer"></span>
        <div class="">
          <span [innerHTML]="timerString" [ngClass]="classString"></span>
        </div>
      </div>
      }

    </div>
  </div>
</div>

<div #contenedor>
  <div class="container-qr hidden download-responsive" id="download-qr">
    <div class="qr-code">
      <div *ngIf="linearSteps[0].label.row && linearSteps[0].label.img" class="step" style="margin: 0.5rem;">
        <div class="label">
          <span class="title-qr">
            @if(isDeuna){
            Escanea el QR y <span class="highlight">págalo <br> desde tu wallet Deuna</span>
            }@else{
            <ng-container *ngIf="esResponsive$ | async; else nonResponsiveText">
              Descarga el QR, súbelo a tu galería y <span class="highlight">págalo desde tu billetera favorita</span>
            </ng-container>
            <ng-template #nonResponsiveText>
              Escanea el QR y <span class="highlight">págalo desde tu billetera favorita</span>
            </ng-template>
            }


          </span>
          <img class="image-qr" [src]="base64QRCode">
        </div>
        <img style="width: 100px;" src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
      </div>
    </div>

  </div>
</div>