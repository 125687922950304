import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessorSelectionContainerComponent } from '@angular-monorepo/processor-selection';

@Component({
  selector: 'dcv-processor-selection',
  standalone: true,
  imports: [CommonModule, ProcessorSelectionContainerComponent],
  templateUrl: './processor-selection.component.html',
  styleUrl: './processor-selection.component.scss',
})
export class ProcessorSelectionComponent { }
