<div class="container">
  <div class="container-voucher">
    @if(isLoading) {
    <div class="spinner">
      <ui-custom-spinner></ui-custom-spinner>
    </div>
    } @else {
    <ng-container *ngIf="voucherData?.voucherConfig?.version === 'V3'; else versionesPrevias">
      <ps-processor-container [voucher]="voucherData" [contador]="expirationTime"></ps-processor-container>
    </ng-container>

    <ng-template #versionesPrevias>
      <ui-card [voucherCard]="true">
        <div class="title" slot="card_title">
          <ng-container *ngIf="voucherData">
            <dcv-voucher-header [voucherConfiguracion]="voucherData.voucherConfig"
              (cambioMetodo)="handleCambioMetodo($event)" [linearSteps]="linearSteps" [title]="'Información de pago'"></dcv-voucher-header>
          </ng-container>
        </div>
        <div class="content" slot="card_content">
          @if(voucherData && voucherData.voucherConfig) {

          @if(voucherData.voucherConfig.version === 'V2') {
          <ps-processor-networks [metodoPagoTipoPersona]="metodoPagoTipoPersona" [config]="voucherData?.voucherConfig"
            (onSelectProcessor)="onProcessorChange($event)"
            (changeMethod)="handleChangeMethod($event)"></ps-processor-networks>
          }



          @if(processors.length > 1 && (voucherData?.voucherConfig?.version === undefined ||
          voucherData?.voucherConfig?.version === 'V1')) {
          <div class="processor-selectable">
            <ps-processor-selectable [processors]="processors" [selectedProcessor]="selectedProcessor.name"
              (onSelectProcessor)="onProcessorChange($event)"></ps-processor-selectable>
          </div>
          }
          }

          @if(selectedProcessor) {
          <sh-banner [bannerInput]="banner"></sh-banner>
          } @else {
          <br>
          }
          <div class="voucher">
            <dcv-direct-connections-voucher
              [directConnectionsVoucherInput]="directConnectionsVoucherInput"></dcv-direct-connections-voucher>
          </div>
        </div>
        <div class="actions" slot="card_actions">
          <div class="logotipe">
            <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
          </div>
        </div>
      </ui-card>
    </ng-template>
    }
    <div class="card-body" [style]="'display: none;'">
      <countdown #cd [config]="{ leftTime: 0 }" />
    </div>
    <div [ngClass]="showTemplate ? 'block' : 'none'">
      <dcv-voucher-pdf-container [linearStep]="linearSteps" />
    </div>
  </div>
</div>