import { Component, Input, OnChanges, SimpleChanges, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherTemplateComponent, VoucherTemplateInput } from '@angular-monorepo/pdf';
import { CashProcessorService, GetPaymentResponseRequest } from '@angular-monorepo/api-services';
import { InstructionStepper, LinearStepInfo } from '@angular-monorepo/shared';

@Component({
  selector: 'dcv-voucher-pdf-container',
  standalone: true,
  imports: [CommonModule, VoucherTemplateComponent],
  templateUrl: './voucher-pdf-container.component.html',
  styleUrl: './voucher-pdf-container.component.scss',
})
export class VoucherPdfContainerComponent implements OnChanges {
  @Input({ required: true }) linearStep: LinearStepInfo[] = []
  @Input() color:string;
  VoucherTemplateInput = null as any;
  pdfVoucher: VoucherTemplateInput = null as any
  voucherData: GetPaymentResponseRequest = null as unknown as any;
  linearStepInfo: LinearStepInfo[] = []

  constructor(
    private cashProcessorService: CashProcessorService,
  ) {
    effect(() => {
      if (this.cashProcessorService.paymentData().voucherConfig) {
        this.voucherData = { ...this.cashProcessorService.paymentData(), voucherConfig: JSON.parse(this.cashProcessorService.paymentData()?.voucherConfig as unknown as any) }
        this.initPdfVoucher()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['linearStep']) {
      this.linearStepInfo = changes['linearStep'].currentValue;
      this.pdfVoucher = {
        ...this.pdfVoucher,
        LinearStepInfo: this.linearStepInfo,
      }
    }
  }

  initPdfVoucher(): void {
    this.pdfVoucher = {
      LinearStepInfo: this.linearStep,
      paymentInstructions: this.voucherData.voucherConfig.bankList.map((bank) => {
        return {
          bank: bank.bankCode,
          hexColor: bank.hexColor,
          instruction: [...bank.paymentMethodInstructions.map((instruction) => {
            return instruction.instructions.map((instruction) => {
              return {
                step: instruction.order,
                description: instruction.value,
              } as unknown as InstructionStepper
            })
          })[0] as unknown as InstructionStepper[]]
        }
      })
    }
  }
}
