import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from '@angular-monorepo/ui';
import { LinearStepInfo, LinearStepInfoComponent } from '@angular-monorepo/shared';
import { PaymentMethodPersonaType, VoucherConfig } from '@angular-monorepo/api-services';

@Component({
  selector: 'dcv-voucher-header',
  standalone: true,
  imports: [CommonModule, IconButtonComponent, LinearStepInfoComponent,],
  templateUrl: './voucher-header.component.html',
  styleUrl: './voucher-header.component.scss',
})
export class VoucherHeaderComponent implements OnChanges {
  @Input({ required: false }) title: string = '';
  @Input() linearSteps: LinearStepInfo[] = [];
  @Input() clipboard: boolean = true
  @Input() color: string = 'primary';
  @Input() secondaryColor: string;
  @Input() dividerColor: string = '#C8D5DF';
  @Input() voucherConfiguracion: VoucherConfig;
  metodos: PaymentMethodPersonaType[];
  metodoSeleccionado: PaymentMethodPersonaType;

  @Output() cambioMetodo = new EventEmitter<PaymentMethodPersonaType>();

  ngOnChanges(changes: SimpleChanges): void {
    const version = this.voucherConfiguracion?.version;
    if (changes && version === "V2" && changes["voucherConfiguracion"] && changes["voucherConfiguracion"].currentValue) {
      this.metodos = this.voucherConfiguracion.payment_method_person_types;
      if (this.metodos.length === 1) {
        this.metodoSeleccionado = this.metodos[0]
      } else {
        this.metodoSeleccionado = this.metodos.find(t => t.code === this.voucherConfiguracion.personTypeSelected)!
      }

      this.cambioMetodo.emit(this.metodoSeleccionado)
    }

    if (changes['linearSteps']) {
      this.linearSteps = changes['linearSteps'].currentValue;
    }
  }

  onSeleccionarMetodo(metodo: PaymentMethodPersonaType) {
    this.metodoSeleccionado = metodo;
    this.cambioMetodo.emit(this.metodoSeleccionado)
  }
}
