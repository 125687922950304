import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map, Observable, shareReplay, Subscription } from 'rxjs';
import { Bank, PaymentMethodPersonaType, PaymentMethodType, VoucherConfig } from "@angular-monorepo/api-services"
import { EnvironmentService } from '@angular-monorepo/environment';
import { Processors } from '../processor-selectable/processor-selectable.interfaces';
import { MatIconModule } from '@angular/material/icon';
import { EMetodosDePago } from '../modelos/metodos-de-pago';

@Component({
    selector: 'ps-processor-networks',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './processor-networks.component.html',
    styleUrls: ['./processor-networks.component.scss'],
})
export class ProcessorNetworkseComponent implements OnInit, OnChanges, OnDestroy {
    private readonly subscriptions = new Subscription();
    @Input() config: VoucherConfig | undefined
    @Input() voucherPayin: boolean = false;
    @Output() onSelectProcessor = new EventEmitter<Processors>();
    @Output() changeMethod = new EventEmitter<string>();

    isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width:480px)']).pipe(
        map((result: BreakpointState) => result.matches),
        shareReplay(1),
    )

    @Input() metodoPagoTipoPersona: PaymentMethodPersonaType;
    metodosDePago: string[];
    filtroActivo: PaymentMethodType | undefined;

    activeBank: Bank | undefined = undefined;
    elementByPage: number = 12;
    totalPages: number = 1;
    processors: any[] = []
    activeProcessors: any[] = [];
    processorsCash: Bank[] = [];
    processorsBankTransfer: Bank[] = [];
    processorsWallet: Bank[] = [];
    processorsBankTransferBusiness: Bank[] = [];
    activePage: number = 1;

    constructor(private readonly breakpointObserver: BreakpointObserver, private environmentService: EnvironmentService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes["config"]?.currentValue) {
            this.processorsCash = this.config ? this.config.bankList?.filter(b => {
                return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.CASH)
            }) : [];

            this.processorsBankTransfer = this.config ? this.config.bankList?.filter(b => {
                return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.BANK_TRANSFER)
            }) : [];

            this.processorsWallet = this.config ? this.config.bankList?.filter(b => {
                return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.WALLET)
            }) : [];

            this.processorsBankTransferBusiness = this.config ? this.config.bankList?.filter(b => {
                return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.BANK_TRANSFER_BUSINESS)
            }) : [];
        }

        if (changes && changes["metodoPagoTipoPersona"]?.currentValue) {
            const metodo = this.config?.methodSelected ? this.metodoPagoTipoPersona.methods?.find(m => m.code === this.config?.methodSelected) : undefined;
            this.filtroActivo = metodo || this.metodoPagoTipoPersona.methods[0];
            this.changeActivedProccesors();
        }
    }

    ngOnInit(): void {
        this.isMobile$.subscribe(isMobile => {
            this.elementByPage = isMobile ? 6 : 12;
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getPagesArray(): number[] {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    setActivePage(page: number) {
        this.activePage = page;
        this.paginar();
    }

    onNextPage() {
        if (this.activePage >= this.totalPages) return;
        this.activePage = this.activePage + 1;
        this.paginar();
    }

    onPreviousPage() {
        if (this.activePage <= 1) return;
        this.activePage = this.activePage > 0 ? this.activePage - 1 : 0;
        this.paginar();
    }

    onSeleccionarFiltro(filtro: PaymentMethodType) {
        this.filtroActivo = filtro;
        this.changeMethod.emit(filtro.code);
        this.activePage = 1;
        this.changeActivedProccesors();
    }

    parseImage(processor: string): string {
        const environment = this.environmentService?.env?.production ? 'prod' : 'dev';

        if (processor === this.activeBank?.bankCode) {
            return `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V2/collectors/${processor}/secondary.webp`;
        } else {
            return `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V2/collectors/${processor}/primary.webp`;
        }
    }

    changeActivedProccesors() {
        console.log("Filtro activo: ", this.filtroActivo?.code)

        switch (this.filtroActivo?.code) {
            case EMetodosDePago.WALLET:
                this.processors = this.processorsWallet;
                break;

            case EMetodosDePago.BANK_TRANSFER:
                this.processors = this.processorsBankTransfer;
                break;

            case EMetodosDePago.CASH:
                this.processors = this.processorsCash;
                break;

            case EMetodosDePago.BANK_TRANSFER_BUSINESS:
                this.processors = this.processorsBankTransferBusiness;
                break;

            default:
                this.processors = this.processorsWallet;
                break;
        }

        this.paginar();

    }

    paginar() {
        const startIndex = (this.activePage - 1) * this.elementByPage;
        const endIndex = startIndex + this.elementByPage;
        this.activeProcessors = this.processors.slice(startIndex, endIndex);
        this.totalPages = Math.ceil(this.processors.length / this.elementByPage);

        const collectorSelected = this.config?.collectorSelected ? this.activeProcessors.find(p => p.bankCode === this.config?.collectorSelected) : undefined;
        const active = collectorSelected ? collectorSelected : this.activeProcessors[0];

        this.selectProcessor(active)
    }

    selectProcessor(processor: Bank) {
        const environment = this.environmentService?.env?.production ? 'prod' : 'dev';
        this.activeBank = processor
        const processorRef = {
            name: processor.bankCode,
            img: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V1/collectors/${processor.bankCode}/banner.svg`,
            hexColor: processor?.hexColor || '',
        } as unknown as Processors
        this.onSelectProcessor.emit(processorRef)
    }
}
