<div class="container">
  <div class="title">
    <h2>Selecciona tu entidad favorita para conocer las instrucciones de pago</h2>
  </div>
  <div class="selector-container">
    <div class="selector">
      @for (processor of processors; track $index) {
      <div [ngClass]="voucherPayin ? 'processor-voucher-payin' : 'processor'">
        @if(processor?.name === selectedProcessor) {
        <button mat-button (click)="selectProcessor(processor)" class="negative">
          <img [src]="parseSecondaryImage(processor.name)" [alt]="processor?.name"
            [ngClass]="processor.name === 'YAPE' ? 'yapeLogotipe' : processor.name === 'PAGO_FACIL' ? 'pagoFacilLogo': processor.name === 'YA_GANASTE' ? 'yaGanaste' : ''" 
            [ngStyle]="cobroDigitalBanner(processor.name)"
            loading="lazy"/>
        </button>
        }
        @if(processor?.name != selectedProcessor) { 
        <button mat-button (click)="selectProcessor(processor)">
          <img [src]="parsePrimaryImage(processor.name)" [alt]="processor?.name"
            [ngClass]="processor.name === 'YAPE' ? 'yapeLogotipe' : 
            processor.name === 'PAGO_FACIL' ? 'pagoFacilLogo' : processor.name === 'YA_GANASTE' ? 'yaGanaste' : ''" 
            [ngStyle]="cobroDigitalBanner(processor?.name)"
            loading="lazy"/>
        </button>
        }
      </div>
      }
    </div>
  </div>
</div>
