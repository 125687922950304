import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from '@angular-monorepo/shared';

@Component({
  selector: 'dvc-not-found',
  standalone: true,
  imports: [CommonModule, NotFoundPageComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent { }
