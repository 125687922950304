<ng-container *ngIf="voucher">
    <div class="voucher">
        <div class="voucher__header">
            <div class="voucher__header__menus">
                <ng-container *ngFor="let metodo of metodos">
                    <div class="voucher__header__menus__menu"
                        [ngClass]="{ 'voucher__header__menus__menu--active': metodoActivo === metodo }"
                        (click)="onCambiarMetodo(metodo)">
                        <div class="voucher__header__menus__menu__item">
                            <span class="voucher__header__menus__menu__item__title"
                                [ngClass]="{ 'voucher__header__menus__menu__item__title--active': metodoActivo === metodo }">
                                {{ textoMenu(metodo) }}
                            </span>

                            <ng-container *ngIf="!(isSmallDevice$ | async)">
                                <img [src]="iconoMenu(metodo)" alt="Item menu" />
                            </ng-container>
                        </div>
                    </div>

                </ng-container>
            </div>

            <ng-container *ngIf="!(isMobile$ | async)">
                <div class="voucher__header__logo">
                    <img src="./assets/v3/logo-monnet-v3-blanco.png" alt="Monnet" />
                </div>
            </ng-container>
        </div>

        <div class="voucher__body">
            <h3 class="voucher__body__title">
                ¡Todas tus redes de pago en un solo lugar! Selecciona tu canal favorito
            </h3>

            <div class="voucher__body__networks">
                <ng-container *ngFor="let banco of bancosActivos">
                    <div class="voucher__body__networks__item" (click)="onCambiarBancoActivo(banco)"
                        [ngClass]="{ 'voucher__body__networks__item--active': banco.bankCode == bancoActivo?.bankCode }">
                        <img [src]="banco.img" [alt]="banco.bankCode" />
                    </div>
                </ng-container>
            </div>

            <div class="voucher__body__details">
                <div class="voucher__body__details__code">
                    <p class="voucher__body__details__code__title">Código de pago</p>
                    <p class="voucher__body__details__code__data">{{ voucher.paymentCode }}</p>
                    <div class="voucher__body__details__code__action" (click)="onCopiarCodigo(voucher.paymentCode)">
                        <img src="./assets/v3/copiar.svg" alt="" />
                        <p>Copiar</p>
                    </div>
                </div>

                <div class="voucher__body__details__total">
                    <p class="voucher__body__details__total__title">Total a pagar</p>
                    <div class="voucher__body__details__total__data">
                        <p>{{ voucher.currencyISO }}</p>
                        <p>{{ voucher.amount }}</p>
                    </div>
                </div>

                <div class="voucher__body__details__date">
                    <p class="voucher__body__details__date__title">
                        {{ voucher.status === 'AUTORIZADO' || estaExpirado ? 'Estado' : 'Vence en' }}</p>
                    <ng-container *ngIf="voucher.status === 'AUTORIZADO'; else contador">
                        <p class="voucher__body__details__date__realizado">Pago Realizado</p>
                    </ng-container>
                    <ng-template #contador>
                        <ng-container *ngIf="!estaExpirado; else expirado">
                            <p class="voucher__body__details__date__value">{{ tiempo }}</p>
                            <p class="voucher__body__details__date__description">{{ textoDetalleExpiracion }}</p>
                        </ng-container>
                        <ng-template #expirado>
                            <p class="voucher__body__details__date__expirado">Tiempo expirado</p>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </div>

        <ng-container *ngIf="bancoActivo">
            <div class="voucher__banner" [style.background]="bancoActivo.hexColor">
                <h3 class="voucher__banner__title">Estás pagando con</h3>
                <img class="voucher__banner__logo" [ngClass]="bancoActivo.bankCode + '_design'"
                    [src]="bancoActivo.imgBanner" [alt]="bancoActivo.bankCode" />
            </div>
        </ng-container>

        <div class="voucher__footer">
            <ng-container *ngIf="bancoActivo">
                <div class="voucher__footer__instructions">
                    <ng-container *ngFor="let instruccion of bancoActivo.paymentMethodInstructions[0]?.instructions">
                        <div class="voucher__footer__instructions__item">
                            <div class="voucher__footer__instructions__item__order">{{ instruccion.order }}</div>
                            <p class="voucher__footer__instructions__item__label" [innerHTML]="instruccion.value"></p>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="bancoActivo?.paymentMethodInstructions?.[0]?.urlHomeBanking">
                <button class="voucher__footer__banca" (click)="onIrBanca()">
                    Accede a tu Banca Web
                    <img src="./assets/v3/flecha-en-circulo.svg" alt="Accede a tu banca web" />
                </button>
            </ng-container>

            <div class="voucher__footer__download">
                <p class="voucher__footer__download__title">Descarga las instrucciones: </p>

                <button class="voucher__footer__download__action" (click)="onDownload()">
                    <img src="./assets/v3/download.svg" alt="Descargar" />
                </button>

                <button class="voucher__footer__download__action" (click)="onPrint()">
                    <img src="./assets/v3/print.svg" alt="Imprimir" />
                </button>
            </div>

            <ng-container *ngIf="(isMobile$ | async)">
                <div class="voucher__footer__logo">
                    <img src="./assets/v3/monnet-logo-v3-color.png" alt="Monnet" />
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="capturando">
        <div class="imprimible" id="imprimible" #imprimible>
            <div class="imprimible__header">
                <p>Información de pago</p>
            </div>

            <div class="imprimible__detalle">
                <div class="imprimible__detalle__monto">
                    <p class="imprimible__detalle__monto__titulo">Monto a pagar</p>
                    <div class="imprimible__detalle__monto__valor">
                        <p>{{ voucher.currencyISO }}</p>
                        <p>{{ voucher.amount }}</p>
                    </div>
                </div>

                <div class="imprimible__detalle__codigo">
                    <p class="imprimible__detalle__codigo__titulo">Código de pago</p>
                    <p class="imprimible__detalle__codigo__dato">{{ voucher.paymentCode }}</p>
                </div>

                <div class="imprimible__detalle__vencimiento">
                    <p class="imprimible__detalle__vencimiento__titulo">Vence en</p>
                    <p class="imprimible__detalle__vencimiento__fecha">{{ fechaHoraExpiracion }}</p>
                </div>
            </div>

            <div class="imprimible__titulo">
                <p>Instrucciones de pago </p>
            </div>

            <div class="imprimible__metodos">
                <ng-container *ngFor="let metodo of metodos; let i = index">
                    <ng-container [ngSwitch]="metodo">
                        <div *ngSwitchCase="'BankTransfer'" class="imprimible__metodos__metodo"
                            [ngClass]="{'imprimible__metodos__metodo__separador': i === 0}">
                            <div class="imprimible__metodos__metodo__titulo">
                                <p>
                                    Banca Web ó App
                                    <img src="./assets/transfer.png" alt="Banca Web ó App" />
                                </p>
                            </div>

                            <!--  <div class="imprimible__metodos__metodo__bancos">
                                <ng-container *ngFor="let bancoTransferencia of bancosTransferencia">
                                    <div class="imprimible__metodos__metodo__bancos__banco">
                                        <img [src]="bancoTransferencia.img" [alt]="bancoTransferencia.bankCode" />
                                    </div>
                                </ng-container>
                            </div>
 -->
                            <div class="imprimible__metodos__metodo__instrucciones">
                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        1.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Ingresa a tu <strong>Banca Web o aplicación móvil de tu red favorita.</strong>
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        2.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Selecciona la opción <strong>“Pago de Servicios”</strong> y busca la
                                        empresa/institución
                                        <strong
                                            class="imprimible__metodos__metodo__instrucciones__item__label--blue">“MONNET
                                            PAGOS”</strong>.
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        3.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Elige el tipo de moneda del servicio e ingresa tu código de pago.
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        4.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Confirma y ¡listo!​ <strong>la operación se procesará automáticamente</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div *ngSwitchCase="'Cash'" class="imprimible__metodos__metodo"
                            [ngClass]="{'imprimible__metodos__metodo__separador': i === 0}">
                            <div class="imprimible__metodos__metodo__titulo">
                                <p>
                                    Agentes ó Ventanilla
                                    <img src="./assets/cash.png" alt="Agentes ó Ventanilla" />
                                </p>
                            </div>

                            <!--  <div class="imprimible__metodos__metodo__bancos">
                                <ng-container *ngFor="let bancoCash of bancosCash">
                                    <div class="imprimible__metodos__metodo__bancos__banco">
                                        <img [src]="bancoCash.img" [alt]="bancoCash.bankCode" />
                                    </div>
                                </ng-container>
                            </div> -->

                            <div class="imprimible__metodos__metodo__instrucciones">
                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        1.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Acércate al <strong>punto de pago de tu red favorita.</strong>
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        2.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Indica que pagarás el servicio de
                                        <strong>“MONNET
                                            PAGOS”</strong>.
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        3.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Indica el código de pago y verifica el monto a pagar.
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        4.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Confirma y ¡listo!​ <strong>la operación se procesará automáticamente.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div *ngSwitchCase="'Wallet'" class="imprimible__metodos__metodo"
                            [ngClass]="{'imprimible__metodos__metodo__separador': i === 0}">
                            <div class="imprimible__metodos__metodo__titulo">
                                <p>
                                    Billetera digital
                                    <img src="./assets/wallet.png" alt="Agentes ó Ventanilla" />
                                </p>
                            </div>

                            <!--  <div class="imprimible__metodos__metodo__bancos">
                                <ng-container *ngFor="let bancoCash of bancosCash">
                                    <div class="imprimible__metodos__metodo__bancos__banco">
                                        <img [src]="bancoCash.img" [alt]="bancoCash.bankCode" />
                                    </div>
                                </ng-container>
                            </div> -->

                            <div class="imprimible__metodos__metodo__instrucciones">
                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        1.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Ingresa a tu App Yape y selecciona la opción <strong>“Yapear
                                            Servicios”.</strong>
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        2.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Elige la categoría <strong>“Compras por Internet”</strong> y luego <strong
                                            class="imprimible__metodos__metodo__instrucciones__item__label--blue">“MONNET PAGOS”</strong>
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        3.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Selecciona la <strong>moneda y servicio</strong> e ingresa tu código de pago.
                                    </p>
                                </div>

                                <div class="imprimible__metodos__metodo__instrucciones__item">
                                    <p class="imprimible__metodos__metodo__instrucciones__item__order">
                                        4.
                                    </p>
                                    <p class="imprimible__metodos__metodo__instrucciones__item__label">
                                        Selecciona el servicio y ¡listo! <strong>la operación se procesará
                                            automáticamente.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="imprimible__logo">
                <img src="./assets/v3/monnet-logo-v3-color.png" alt="monnet" />
            </div>
        </div>
    </ng-container>
</ng-container>