@if(isLoading) {
<div class="spinner">
  <ui-custom-spinner></ui-custom-spinner>
</div>
} @else {
<ui-card [voucherCard]="true">
  <div class="title" slot="card_title">
    <dcv-voucher-header [linearSteps]="linearSteps" [title]="'Información de pago'"></dcv-voucher-header>
  </div>
  <div class="content" slot="card_content">
    @if(voucherData && voucherData.voucherConfig) {

    @if(voucherData.voucherConfig.version == 'V2') {
      <ps-processor-networks [config]="voucherData?.voucherConfig" (onSelectProcessor)="onProcessorChange($event)"
        (changeMethod)="handleChangeMethod($event)"></ps-processor-networks>
    }


    @if(processors.length > 1 && (voucherData?.voucherConfig?.version === undefined ||
    voucherData?.voucherConfig?.version === 'V1')) {
    <div class="processor-selectable">
      <ps-processor-selectable [processors]="processors" [selectedProcessor]="selectedProcessor.name"
        (onSelectProcessor)="onProcessorChange($event)"></ps-processor-selectable>
    </div>
    }
    }

    @if(selectedProcessor) {
    <sh-banner [bannerInput]="banner"></sh-banner>
    } @else {
    <br>
    }
    <div class="voucher">
      <dcv-direct-connections-voucher
        [directConnectionsVoucherInput]="directConnectionsVoucherInput"></dcv-direct-connections-voucher>
    </div>
  </div>
  <div class="actions" slot="card_actions">
    <div class="logotipe">
      <img src="./assets/svg/powered-by-monnet-payment-solutions.svg" alt="">
    </div>
  </div>
</ui-card>
}
<div class="card-body" [style]="'display: none;'">
  <countdown #cd [config]="{ leftTime: 0 }" />
</div>
<div [ngClass]="showTemplate ? 'block' : 'none'">
  <dcv-voucher-pdf-container [linearStep]="linearSteps" />
</div>