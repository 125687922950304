import { Route } from '@angular/router';
import { ErrorComponent, NotFoundComponent, ProcessorSelectionComponent, VoucherComponent } from './pages';

export const appRoutes: Route[] = [
  {
    path: 'processor-selection',
    component: ProcessorSelectionComponent,
  },
  {
    path: 'voucher/:merchantId/:operationId',
    component: VoucherComponent,
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
