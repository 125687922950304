import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectConnectionsVoucherContainerComponent } from '@angular-monorepo/direct-connections-voucher';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';

@Component({
  selector: 'dcv-voucher',
  standalone: true,
  imports: [CommonModule, DirectConnectionsVoucherContainerComponent, CustomSpinnerComponent,],
  templateUrl: './voucher.component.html',
  styleUrl: './voucher.component.scss',
})
export class VoucherComponent {
  isAutoClass: boolean = false;

  onLoadingExport(loading: boolean) {
    this.isAutoClass = loading;
  }
}
