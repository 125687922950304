import { Bank, GetPaymentResponseRequest, Instruction } from "@angular-monorepo/api-services";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, ElementRef, inject, Input, OnInit, ViewChild, } from "@angular/core"; 
import { EnvironmentService } from "@angular-monorepo/environment";
import { Observable, map, shareReplay } from "rxjs";
import moment from 'moment';
import html2canvas from 'html2canvas';
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { EMetodosDePago } from "../modelos/metodos-de-pago";

@Component({
    selector: 'ps-processor-container',
    standalone: true,
    imports: [CommonModule, MatSnackBarModule],
    templateUrl: './processor-container.component.html',
    styleUrls: ['./processor-container.component.scss'],
})
export class ProcessorContainerComponent implements OnInit {
    @ViewChild('imprimible', { static: false }) imprimible!: ElementRef;

    @Input() voucher: GetPaymentResponseRequest;
    @Input() set contador(valor: number) {
        this.tiempo = this.formatearTiempo(valor);
    };

    tiempo: string = ""
    estaExpirado: boolean = false
    textoDetalleExpiracion: string = "";
    fechaHoraExpiracion: string = "";

    bancos: Bank[] = []
    bancosCash: Bank[] = [];
    bancosTransferencia: Bank[] = [];
    bancosWallet: Bank[] = []
    bancosActivos: Bank[] = []
    bancoActivo: Bank | undefined = undefined;

    metodos: string[] = []
    metodoActivo: string | undefined = undefined;

    instrucciones: Instruction | undefined = undefined;

    isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width:600px)']).pipe(
        map((result: BreakpointState) => result.matches),
        shareReplay(1),
    )

    isSmallDevice$: Observable<boolean> = this.breakpointObserver.observe(['(max-width:390px)']).pipe(
        map((result: BreakpointState) => result.matches),
        shareReplay(1),
    )


    capturando: boolean = false;

    constructor(private environmentService: EnvironmentService, private readonly breakpointObserver: BreakpointObserver, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        if (this.voucher) {

            this.bancos = this.voucher.voucherConfig.bankList.map(b => {
                const environment = this.environmentService?.env?.production ? 'prod' : 'dev';

                return {
                    ...b,
                    img: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V3/collectors/${b.bankCode}/primary.webp`,
                    imgBanner: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V3/collectors/${b.bankCode}/banner.svg`,

                }
            });
            this.metodos = this.voucher.voucherConfig.instruction_payment_methods;
            this.metodoActivo = this.voucher.voucherConfig.methodSelected || this.voucher.voucherConfig.instruction_payment_methods[0];

            if (this.bancos && this.bancos.length > 0) {
                this.bancosCash = this.bancos.filter(b => {
                    return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.CASH)
                });

                this.bancosTransferencia = this.bancos.filter(b => {
                    return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.BANK_TRANSFER)
                });

                this.bancosWallet = this.bancos.filter(b => {
                    return b.paymentMethodInstructions?.some(instruction => instruction.method === EMetodosDePago.WALLET)
                });

                const bancoSeleccionado = this.voucher.voucherConfig.collectorSelected;
                if (bancoSeleccionado) {
                    switch (this.metodoActivo) {
                        case "Cash": {
                            this.bancoActivo = this.bancosCash.find(p => p.bankCode === bancoSeleccionado);
                            break;
                        }

                        case "BankTransfer": {
                            this.bancoActivo = this.bancosTransferencia.find(p => p.bankCode === bancoSeleccionado);
                            break;
                        }

                        case "Wallet": {
                            this.bancoActivo = this.bancosWallet.find(p => p.bankCode === bancoSeleccionado);
                            break;
                        }
                    }
                }

                const codigoSeleccionado = this.voucher.voucherConfig.collectorSelected;
                this.seleccionarBancos(codigoSeleccionado);
            }

            this.armarTextoExpiracion();
            this.armarFechaHoraExpiracion();

            setInterval(() => {
                this.iniciarContador();
            }, 1000);
        }

    }

    onCambiarBancoActivo(banco: Bank) {
        this.bancoActivo = banco;
    }

    onCambiarMetodo(metodo: string) {
        this.metodoActivo = metodo
        this.seleccionarBancos()
    }

    onIrBanca() {
        const url = this.bancoActivo?.paymentMethodInstructions?.[0]?.urlHomeBanking;
        if (url) window.open(url, "_blank")
    }

    private seleccionarBancos(codigoSeleccionado?: string) {
        switch (this.metodoActivo) {
            case EMetodosDePago.WALLET:
                this.bancosActivos = this.bancosWallet;
                break;

            case EMetodosDePago.CASH:
                this.bancosActivos = this.bancosCash;
                break;

            case EMetodosDePago.BANK_TRANSFER:
                this.bancosActivos = this.bancosTransferencia;
                break;
        }

        this.bancoActivo = codigoSeleccionado ? this.bancosActivos.find(b => b.bankCode === codigoSeleccionado) : this.bancosActivos[0]
    }

    private formatearTiempo(seconds: number): string {
        if (seconds < 0) {
            this.estaExpirado = true;
            return "";
        }

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h : ${minutes}m : ${secs}s`;
    }

    private iniciarContador() {
        const expirationDate = moment(this.voucher.expiration).utc(true).diff(moment(), 'seconds');

        if (expirationDate <= 0) {
            this.estaExpirado = true;
            return
        }

        const duration = moment.duration(expirationDate, 'seconds');
        const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
        const minutes = String(duration.minutes()).padStart(2, '0');
        const seconds = String(duration.seconds()).padStart(2, '0');

        this.tiempo = `${hours}h : ${minutes}m : ${seconds}s`;
    }

    private armarTextoExpiracion() {
        moment.locale('es');
        const expirationMoment = moment(this.voucher.expiration).utc(true);
        const formattedDate = expirationMoment.format('D MMM YYYY');
        const formattedTime = expirationMoment.format('HH:mm');

        this.textoDetalleExpiracion = `Paga antes del ${formattedDate} - ${formattedTime} hrs.`;
    }

    private armarFechaHoraExpiracion() {
        moment.locale('es');
        const expirationMoment = moment(this.voucher.expiration).utc(true);
        const formattedDate = expirationMoment.format('DD/MM/YYYY');
        const formattedTime = expirationMoment.format('HH:mm');
        this.fechaHoraExpiracion = `${formattedDate} a las ${formattedTime} horas.`;
    }


    onCopiarCodigo(codigo: string) {
        navigator.clipboard.writeText(codigo)

        this._snackBar.open('Copiado al portapapeles', undefined, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        });
    }

    onDownload() {
        this.capturando = true;

        setTimeout(() => {
            console.log(this.imprimible)
            if (!this.imprimible) return;

            html2canvas(this.imprimible.nativeElement).then((canvas: any) => {
                const imagenBase64 = canvas.toDataURL('image/jpeg');

                const link = document.createElement('a');
                link.href = imagenBase64;
                link.download = 'instrucciones.jpg';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.capturando = false;
            });
        }, 0);
    }

    onPrint() {
        window.print()
    }

    textoMenu(metodo: string) {
        if (metodo === EMetodosDePago.BANK_TRANSFER) {
            return "Banca Web ó App"
        }

        if (metodo === EMetodosDePago.CASH) {
            return "Agentes ó Ventanilla"
        }

        return "Billetera digital"
    }

    iconoMenu(metodo: string): string {
        const activo = this.metodoActivo === metodo;

        if (metodo === EMetodosDePago.BANK_TRANSFER) {
            return activo ? './assets/transfer.png' : './assets/transfer_active.png';
        }

        if (metodo === EMetodosDePago.CASH) {
            return activo ? './assets/cash.png' : './assets/cash_active.png';
        }

        return activo ? './assets/wallet.png' : './assets/wallet_active.png';
    }
}
