<div class="container">
    <div class="title">
        <h2>¡Todas tus redes de pago en un solo lugar! Selecciona tu canal favorito</h2>
    </div>

    <div class="selector-container">
        <div class="selector-container__menu">
            <ng-container *ngFor="let option of config?.instruction_payment_methods">
                <div [ngSwitch]="option" class="selector-container__menu__item"
                    [ngClass]="{ 'selector-container__menu__item--active': activedFilter === option }"
                    (click)="onSeleccionarFiltro(option)">
                    <div class="selector-container__menu__item__container" *ngSwitchCase="'Cash'">
                        <p>Agentes ó <br /> Ventanilla</p>

                        <ng-container *ngIf="!(isMobile$ | async)">
                            <img [src]="activedFilter !== option ? './assets/cash.png' : './assets/cash_active.png'" />
                        </ng-container>
                    </div>
                    <div class="selector-container__menu__item__container" *ngSwitchCase="'BankTransfer'">
                        <p>Banca Web ó <br /> App</p>

                        <ng-container *ngIf="!(isMobile$ | async)">
                            <img
                                [src]="activedFilter !== option ? './assets/transfer.png' : './assets/transfer_active.png'" />
                        </ng-container>
                    </div>
                    <div class="selector-container__menu__item__container" *ngSwitchCase="'Wallet'">
                        <p>Billetera <br /> digital</p>

                        <ng-container *ngIf="!(isMobile$ | async)">
                            <img
                                [src]="activedFilter !== option ? './assets/wallet.png' : './assets/wallet_active.png'" />
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        @if(activeProcessors.length > 1 || (activeProcessors.length === 1 && totalPages !== 1)) {
        <div class="carrousel">
            <div class="carrousel__back">
                <button (click)="onPreviousPage()" [disabled]="activePage === 1" *ngIf="totalPages > 1">
                    <img src="./assets/icon/back-arrow-blue.svg" alt="back" />
                </button>
            </div>
            <div class="carrousel__list">
                <ng-container *ngFor="let processor of activeProcessors">
                    <div class="carrousel__list__item"
                        [ngClass]="{ 'carrousel__list__item--active': activeBank?.bankCode === processor.bankCode}"
                        (click)="selectProcessor(processor)">
                        <img class="carrousel__list__item__logo" [src]="parseImage(processor.bankCode)"
                            [alt]="processor.bankCode" />
                    </div>
                </ng-container>
            </div>
            <div class="carrousel__next">
                <button (click)="onNextPage()" [disabled]="activePage === activeProcessors.length"
                    *ngIf="totalPages > 1">
                    <img src="./assets/icon/next-arrow-blue.svg" alt="back" />
                </button>
            </div>
        </div>
        <div class="paginator" *ngIf="totalPages > 1">
            <button *ngFor="let page of getPagesArray(); let i = index" class="paginator__item"
                (click)="setActivePage(page)" [ngClass]="{ 'paginator__item--active': page === activePage }">
                <p>{{ page }}</p>
            </button>
        </div>
        }
    </div>
</div>