import { MediaButton } from "@angular-monorepo/ui";
import { ThemePalette } from "@angular/material/core";

export const bankTransferPaymentMethodButton = (paymentMethod: string, onClick: () => void): MediaButton => {
  const color: ThemePalette = paymentMethod === 'BankTransfer' ? 'accent' : 'primary'

  return {
    imagesUrl: [image('./assets/svg/phone', color), image('./assets/svg/pc', color)],
    label: 'Banca Móvil | Banca Web',
    onClick: () => onClick(),
    color: color
  }
}

export const cashPaymentMethodButton = (paymentMethod: string, onClick: () => void): MediaButton => {
  const color: ThemePalette = paymentMethod === 'Cash' ? 'accent' : 'primary'

  return {
    imagesUrl: [image('./assets/svg/merchant-home', color)],
    label: 'Agentes y Bodegas',
    onClick: () => onClick(),
    color: color
  }
}

export const walletPaymentMethodButton = (paymentMethod: string, onClick: () => void): MediaButton => {
  const color: ThemePalette = paymentMethod === 'Wallet' ? 'accent' : 'primary'

  return {
    imagesUrl: [image('./assets/svg/phone', color)],
    label: 'APP Móvil',
    onClick: () => onClick(),
    color: color,
  }
}

function image(image: string, color: string): string {
  if (color === 'accent') {
    return `${image}-negative.svg`
  } else {
    return image + '.svg'
  }
}
